<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.financeModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success">
          <h3><img width="38px" src="../assets/icons/tax-certificate.svg" alt="tax"/>&nbsp;&nbsp;&nbsp;Steuerbescheinigungen</h3>
        </div>
      </div>

      <div v-if="facilitiesNotExists" class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Steuerbescheinigungen"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell medium-6">
          <md-table v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                    @md-selected="onSelect" :md-selected-value.sync="selectedRows" style="height: 920px;">
            <md-table-toolbar>
              <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
                <div class="cell large-10 medium-10 small-10">
                  <br>
                  <p class="md-title ai-number" style="margin-left: 0;">{{searched.length}} Steuerbescheinigung<span v-if="searched.length !== 1">en</span> <span
                      class="hide-for-medium-only hide-for-small-only">verfügbar</span></p>
                  <br>
                </div>
                <div class="cell large-2 medium-2 small-2">
                  <div v-if="sending">
                    <vue-simple-spinner></vue-simple-spinner>
                  </div>
                </div>
                <div class="cell large-2 medium-4 small-2">
                  <md-field>
                    <label>Jahr</label>
                    <md-select v-model="selectedYear" :disabled="sending" @md-selected="onBillingYear">
                      <md-option v-for="year of taxCertificateYears" :value="year" :key="year">
                        {{year}}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div v-if="!isSage && availableFacilities && availableFacilities.length > 0"
                     class="cell large-6 medium-8 small-10">
                  <md-field>
                    <label>Einrichtung</label>
                    <md-select v-model="selectedFacilityReferenceNumber"
                               :disabled="sending || availableFacilities.length <= 1"
                               @md-selected="onSelectFacility">
                      <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                                 :key="facility.referenceNumber">
                        {{ facility.longName }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-4 medium-12 small-12">
                  <md-field md-clearable class="md-toolbar-section-end">
                    <md-input placeholder="Suche nach Nachname ..." v-model="search" @input="searchOnTable"/>
                  </md-field>
                </div>
                <div class="cell small-12">
                  <md-switch v-model="multiChildSelection" @change="toggleMultiChildSelection" class="md-primary">Mehrfach-Auswahl <span
                      v-if="multiChildSelection">de</span>aktivieren
                  </md-switch>
                </div>
              </div>
            </md-table-toolbar>
            <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item.id)"
                          :md-selectable="childSelectionMode">
              <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Vorname"
                             md-sort-by="masterdata.firstname">
                <ImageView :imageId="item.imageId" size="micro"></ImageView>&nbsp;&nbsp;&nbsp;&nbsp;{{item.masterdata.firstname }}
              </md-table-cell>
              <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Nachname"
                             md-sort-by="masterdata.lastname">{{ item.masterdata.lastname }}
              </md-table-cell>
              <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Bereich" md-sort-by="divisiontype">{{labels.divisiontype[item.divisiontype] }}
              </md-table-cell>
              <md-table-cell md-label="KiBiG" md-sort-by="kibigName" style="padding: 0;">{{ item.kibigName }}<br>
                <span style="font-size: smaller">{{ item.kibigId }}</span>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <div class="cell medium-6">
          <md-card id="taxCertificateCardId" style="height: 920px;">
            <md-card-header id="taxCertificateCardHeaderId">
              <div v-if="!multiChildSelection">
                <div v-if="selectedChild" class="grid-x grid-padding-x">
                  <div v-if="pdfLoading" class="cell">
                    <p class="md-sub-title">Erstelle Steuerbescheinigung {{selectedYear}} für
                      {{selectedChild.masterdata.fullName}} ...</p>
                    <md-progress-bar md-mode="indeterminate"></md-progress-bar>
                  </div>
                  <div v-else class="cell">
                    <p class="md-sub-title">Steuerbescheinigung {{selectedYear}} für
                      {{selectedChild.masterdata.fullName}}</p>

                    <div class="grid-x grid-padding-x">
                      <div class="cell small-12 medium-12 large-6">
                        <button @click="onExportSingleTaxCertificate" class="button primary expanded" :disabled="sending">
                          <i class="fi-download"></i>&nbsp;&nbsp;&nbsp;Steuerbescheinigung
                        </button>
                        <md-tooltip>
                          Steuerbescheinigung als PDF herunterladen
                        </md-tooltip>
                      </div>
                      <div class="cell small-0 medium-0 large-6">
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <h5 class="md-sub-title"><i>Bitte wählen Sie ein Kind in der Liste aus</i></h5>
                </div>
              </div>
              <div v-else>
                <div v-if="selectedChildren.length > 0" class="grid-x grid-padding-x">

                  <div class="cell small-12">
                    <p class="md-sub-title">{{selectedChildren.length}} Steuerbescheinigung
                      <span v-if="selectedChildren.length > 1">en</span> für das Jahr {{selectedYear}} ausgewählt</p>
                    <br>
                  </div>

                  <div class="cell small-12 medium-12 large-6">
                    <button @click="onExportTaxCertificates" class="button primary expanded"
                            :disabled="sending || selectedChildren.length <= 0">
                      <i class="fi-page-export-pdf"></i>&nbsp;&nbsp;&nbsp;
                      <span v-if="selectedChildren.length > 0">{{selectedChildren.length}}&nbsp;</span>
                      Steuerbescheinigung<span v-if="selectedChildren.length !== 1">en</span>&nbsp;
                    </button>
                    <md-tooltip>
                      <span v-if="selectedChildren.length > 0">{{selectedChildren.length}}&nbsp;</span>
                      Steuerbescheinigung<span v-if="selectedChildren.length !== 1">en</span> als PDF herunterladen
                    </md-tooltip>
                  </div>
                  <div class="cell small-12 medium-6">
                  </div>

                </div>
                <div v-else>
                  <h5 class="md-sub-title"><i>Bitte wählen Sie ein Kind oder mehrere Kinder in der Liste aus</i></h5>
                </div>
              </div>
            </md-card-header>
            <md-card-content>
              <div :style="pdfVisibile">
                <md-content id="pdfContentContainerId" class="md-scrollbar md-card">
                  <pdf v-if="pdfUrl" :src="pdfUrl" :page="1" :scale.sync="pdfScale" :resize="false" class="pdf-frame"/>
                </md-content>
              </div>
            </md-card-content>
          </md-card>

        </div>
      </div>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showExportTaxCertificatesDialog">
        <div v-if="selectedChildren.length > 0" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <i class="fi-page-export-pdf"></i>&nbsp;&nbsp;&nbsp;Steuerbescheinigungen als PDF herunterladen
          </md-dialog-title>
          <div class="cell">
            <md-content v-if="progressCount === 0 && !sending">Soll<span v-if="selectedChildren.length !== 1">en</span>&nbsp;<b
                class="group-title">{{selectedChildren.length}}</b>
              Steuerbescheinigung<span v-if="selectedChildren.length !== 1">en</span> für das Jahr <b
                  class="group-title">{{selectedYear}}</b> als PDF heruntergeladen werden?

              <br><br>
              <div class="text-center">
                <md-radio v-model="singlePDF" value="true" class="md-primary">
                  Einzelnes PDF pro Steuerbescheinigung
                </md-radio>
                <md-radio v-model="singlePDF" value="false" class="md-primary">
                  Alle Steuerbescheinigungen in einem PDF
                </md-radio>
              </div>
            </md-content>
            <md-content v-else-if="progressCount === 0 && sending" class="text-center">
              Herunterladen wird vorbereitet ...
            </md-content>
            <md-content v-else class="text-center">
              <b class="group-title">{{progressSuccessCount}}</b>&nbsp;Dokument<span v-if="progressSuccessCount !== 1">e</span> erfolgreich
              herunterladen<span v-if="progressFailureCount > 0">,&nbsp;<b
                class="group-title">{{progressFailureCount}}</b> Downloads fehlgeschlagen</span>
            </md-content>

            <br>
            <md-progress-bar v-if="progress > 0" md-mode="determinate" :md-value="progress"></md-progress-bar>
            <md-progress-bar v-if="progressCount === 0 && sending" md-mode="indeterminate"></md-progress-bar>
          </div>
          <div v-if="progressCount === 0" class="cell text-center">
            <button @click="getTaxCertificates" class="button success" :disabled="sending"><i
                class="fi-page-export-pdf"></i>&nbsp;&nbsp;&nbsp;Ja,
              Steuerbescheinigung<span v-if="selectedChildren.length !== 1">en</span> herunterladen
            </button>
            <button class="button alert" style="margin-left: 1rem;" @click="showExportTaxCertificatesDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <div v-else class="cell text-center">
            <button @click="showExportTaxCertificatesDialog = false" class="button success"
                    :disabled="sending || (progress < 100)"><i class="fi-check"></i>&nbsp;&nbsp;&nbsp;Fenster schliessen
            </button>
          </div>
          <button class="close-button" type="button" @click="showExportTaxCertificatesDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="FINANCE" moduleFunction="Steuerbescheinigungen"></NoModuleCard>
    </div>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import Vue from 'vue';
  import Spinner from 'vue-simple-spinner';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import BankAccountView from '../components/views/BankAccountView';
  import ImageView from '../components/views/ImageView';
  import pdf from 'pdfvuer';
  import FacilityService from "../services/FacilityService";
  import NoFacilityCard from "../components/cards/NoFacilityCard";
  import PdfService from "../services/PdfService";
  import NoModuleCard from '../components/cards/NoModuleCard';

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByLastname = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.masterdata.lastname).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'TaxCertificates',

    components: {
      NoFacilityCard,
      BankAccountView,
      ImageView,
      pdf,
      'vue-simple-spinner': Spinner,
      NoModuleCard,
    },

    mounted() {
      this.restoreUserSettings();
      this.initData();
      this.reloadTaxCertificates();
      window.addEventListener('resize', (event) => {
        this.resizeDialogElements(event);
      }, { passive: true });

      HttpErrorHandler.maintainDarkMode(this);
    },

    beforeDestroy() {
      this.cleanPDFs();
      this.pdfUrl = null;
      this.selectedChild = null;
      this.selected = null;
    },

    data() {
      return {
        sending: false,
        progress: 0,
        progressCount: 0,
        progressSuccessCount: 0,
        progressFailureCount: 0,
        children: [],
        searched: [],
        search: null,
        selected: null,
        selectedRows: [],
        selectedYear: Vue.moment().format("YYYY"),
        taxCertificateYears: [],

        selectedChild: null,
        availableFacilities: [],
        selectedFacilityReferenceNumber: null,
        selectedFacilityName: '',

        selectedRowId: 0,
        selectedChildren: [],

        showExportTaxCertificatesDialog: false,

        multiChildSelection: true,
        multiChildSelectionInitialized: false,
        pdfLoading: false,
        pdfScale: 'page-width',

        pdfUrl: null,
        pdfLinks: [],
        pdfBlobs: [],

        singlePDF: 'true',
      }
    },

    methods: {

      initMultiSelection() {
        if (!this.multiChildSelectionInitialized) {
          this.multiChildSelectionInitialized = true;
          setTimeout(() => {
            this.multiChildSelection = false;
            this.toggleMultiChildSelection();
          }, 250);
        }
      },

      toggleMultiChildSelection() {
        this.search = null;
        this.selected = null;
        this.selectedChild = null;
        const jqTableCellSelection = jQuery('.md-table-cell-selection');
        if (this.multiChildSelection) {
          jqTableCellSelection.children().children().first().show();
          jQuery('.md-table-row.md-has-selection.md-selected-single.md-primary').click();
          jqTableCellSelection.children().children().first().children().first().attr('style','padding-left: 0');
          jqTableCellSelection.attr('style','width: 68px');
        } else {
          jqTableCellSelection.children().children().first().hide();
          jqTableCellSelection.children().children().first().children().first().attr('style','padding-left: 0');
          jqTableCellSelection.attr('style','width: 42px');
          if (this.selectedRows && this.selectedRows.length > 0) {
            if (this.selectedRows && this.selectedRows.length < this.searched.length) {
              jqTableCellSelection.children().children().first().children().first().children().first().children().first().trigger("click");
            }
            setTimeout(() => jqTableCellSelection.children().children().first().children().first().children().first().children().first().trigger("click"), 125)
          }
        }
      },

      restoreUserSettings() {
        this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
        if (this.selectedFacilityReferenceNumber === '*') {
          this.selectedFacilityReferenceNumber = null;
        }
      },

      initData() {
        if (this.taxCertificateYears.length <= 0) {
          if (Vue.moment().isAfter(Vue.moment('01.11.' + Vue.moment().format('YYYY'), 'DD.MM.YYYY'))) {
            this.taxCertificateYears.push(Vue.moment('01-01-' + String(Number(this.selectedYear) + 1), 'DD-MM-YYYY').format('YYYY'));
          }
          for (let i = 0; i < 11; i++) {
            this.taxCertificateYears.push(Vue.moment('01-01-' + String(Number(this.selectedYear) - i), 'DD-MM-YYYY').format('YYYY'));
          }
        }
      },

      resizeDialogElements() {
        setTimeout(() => {
          jQuery('#pdfContentContainerId').height(jQuery('#taxCertificateCardId').height() - jQuery('#taxCertificateCardHeaderId').height() - 45)
        }, 100);
      },

      buildAvailableFacilities() {
        this.availableFacilities = this.getAvailableFacilities;
        for (let i = 0; i < this.availableFacilities.length; i++) {
          if (this.availableFacilities[i].referenceNumber === '*') {
            this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
          }
        }
        if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
          this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
          this.selectedFacilityName = this.availableFacilities[0].name;
        }

        this.onSelectFacility();
      },

      reloadTaxCertificates() {

        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            if (this.isAdmin || this.isTreasurer) {
              if (!this.selectedFacilityReferenceNumber) {
                this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
              }
              this.buildAvailableFacilities();
              this.getFacilityAvailableTaxCertificates4Year();
            } else {
              if (!this.selectedFacilityReferenceNumber) {
                this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
              }
              this.buildAvailableFacilities();
            }

            clearInterval(reloadIntervalId);
            jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'height: 605px');
          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      getFacilityAvailableTaxCertificates4Year() {
        if (this.selectedFacilityReferenceNumber) {
          this.getAvailableTaxCertificates4Year(this.selectedFacilityReferenceNumber);
        }
      },

      getAvailableTaxCertificates4Year(referenceNumber) {
        if (!this.facilitiesNotExists) {
          this.sending = true;
          FacilityService.getAvailableTaxCertificates4Year(referenceNumber, this.selectedYear)
            .then(response => {
              this.children = response.data;
              this.searchOnTable();
              this.sending = false;
              this.initMultiSelection();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Kinder mit verfügbaren Steuerbescheinigungen für Einrichtung mit Nummer '
                + referenceNumber + ' und Jahr ' + this.selectedYear);
              this.sending = false;
            })
        }
      },

      searchOnTable() {
        this.searched = this.children;
        this.searched = searchByLastname(this.searched, this.search);
        setTimeout(() => jQuery('.md-table-cell-container').attr('style', 'padding: 6px 0px 6px 10px;'), 100);
        setTimeout(() => jQuery('table').removeAttr('style'), 250);
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      onSelect(item) {
        // Reset pdfUrl in order to ensure pdf viewer refresh
        this.pdfUrl = null;

        if (this.multiChildSelection) {

          if (item) {
            this.selectedChildren = JSON.parse(JSON.stringify(item));
          } else {
            this.selectedChildren = [];
          }

        } else {
          if (item && item.id) {
            this.selectedRowId = item.id;
            this.selected = JSON.parse(JSON.stringify(item));
            this.selectedChild = JSON.parse(JSON.stringify(this.selected));
            this.getSingleTaxCertificate(this.selectedFacilityReferenceNumber, this.selectedChild);
          } else {
            this.selected = null;
            this.selectedChild = null;
          }
        }
      },

      onSelectFacility() {
        if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

          localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

          for (let fac of this.availableFacilities) {
            if (fac.referenceNumber === this.selectedFacilityReferenceNumber) {
              this.selectedFacilityName = fac.name;
            }
          }

          this.selected = null;
          this.selectedChild = null;

          this.getFacilityAvailableTaxCertificates4Year();
        }
      },

      getInvoiceTemplateDataForChild(config, child) {
        this.sending = true;
        this.pdfLoading = true;
        PdfService.getTemplateData(config)
          .then(response => {
            this.sending = false;
            this.pdfLoading = false;
            this.createTaxCertificatePdfForChild(response.data, child)
          })
          .catch(e => {
            this.sending = false;
            this.pdfLoading = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der PDF Template Daten für Kind ' + child.masterdata.fullName + ' zur Erstellung der Steuerbescheinigungen für das Jahr ' + this.selectedYear);
          })
      },

      createTaxCertificatePdfForChild(template, child) {
        this.cleanPDFs();
        this.sending = true;
        this.pdfLoading = true;
        PdfService.createPdf(template)
          .then(response => {
            setTimeout(() => {
              this.pdfBlobs.push(new Blob([response.data, 'utf-8'], {type: "application/pdf"}));
              this.pdfUrl = window.URL.createObjectURL(this.pdfBlobs[this.pdfBlobs.length - 1]);

              this.pdfLinks.push(document.createElement('a'));
              const id = this.selectedFacilityName + ' (' + this.selectedFacilityReferenceNumber + ') Steuerbescheinigung ' + this.selectedYear + ' ' + child.masterdata.fullName;
              this.pdfLinks[this.pdfLinks.length - 1].href = this.pdfUrl;
              this.pdfLinks[this.pdfLinks.length - 1].setAttribute('download', id + '.pdf');
              this.pdfLinks[this.pdfLinks.length - 1].setAttribute('id', id);
              document.body.appendChild(this.pdfLinks[this.pdfLinks.length - 1]);

              this.sending = false;
              this.pdfLoading = false;

              this.resizeDialogElements();

            }, 250);
          })
          .catch(e => {
            this.sending = false;
            this.pdfLoading = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Steuerbescheinigung für Kind ' + child.masterdata.fullName + ' und Jahr ' + this.selectedYear);
          })
      },

      getSingleTaxCertificate(referenceNumber, child) {
        let childConfig = {
          facilityReference: this.selectedFacilityReferenceNumber,
          childId: child.id,
          dsgvoPersonId: null,
          year: this.selectedYear,
          month: 1,
          template: {
            type: 'TAXFORM',
            content: '',
            name: '',
            standard: false,
            printMargins: {
              top: 0,
              right: 0,
              left: 0,
              bottom: 0
            },
            forceUseOfLetterPaper: 0,
          },
          preview: false,
        }

        this.getInvoiceTemplateDataForChild([childConfig], child);
      },

      cleanPDFs() {
        if (this.pdfUrl) {
          this.pdfUrl = window.URL.revokeObjectURL(this.pdfUrl);
          for (let i = 0; i < this.pdfBlobs.length; i++) {
            this.pdfBlobs[i] = null;
          }
          while (this.pdfBlobs.length > 0) {
            this.pdfBlobs.pop();
          }
          this.pdfBlobs = [];
        }

        for (let i = 0; i < this.pdfLinks.length; i++) {
          document.body.removeChild(this.pdfLinks[i]);
          this.pdfLinks[i] = null;
        }
        while (this.pdfLinks.length > 0) {
          this.pdfLinks.pop();
        }
        this.pdfLinks = [];
      },

      onBillingYear() {
        this.onSelectFacility();
      },

      onExportSingleTaxCertificate() {
        if (this.pdfLinks.length > 0) {
          this.sending = true;
          setTimeout(() => {
            this.pdfLinks[this.pdfLinks.length - 1].click();
            this.sending = false;
          }, 250);
        }
      },

      onExportTaxCertificates() {
        this.progress = 0;
        this.progressCount = 0;
        this.progressSuccessCount = 0;
        this.progressFailureCount = 0;
        this.showExportTaxCertificatesDialog = true;
      },

      getTaxCertificates() {
        this.cleanPDFs();

        let childrenConfig = [];
        for (let child of this.selectedChildren) {
          let childConfig = {
            facilityReference: this.selectedFacilityReferenceNumber,
            childId: child.id,
            dsgvoPersonId: null,
            year: this.selectedYear,
            month: 1,
            template: {
              type: 'TAXFORM',
              content: '',
              name: '',
              standard: false,
              printMargins: {
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
              },
              forceUseOfLetterPaper: 0,
            },
            preview: false,
          }
          if (this.singlePDF === 'true') {
            this.getTaxCertificate(this.selectedFacilityReferenceNumber, [childConfig])
          } else {
            childrenConfig.push(childConfig);
          }
        }
        if (this.singlePDF !== 'true') {
          this.getTaxCertificate(this.selectedFacilityReferenceNumber, childrenConfig)
        }
      },

      getTaxCertificate(referenceNumber, config) {
        this.sending = true;
        PdfService.getTemplateData(config)
          .then(response => {
            this.sending = false;
            this.createTaxCertificatePdf(referenceNumber, response.data)
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der PDF Template Daten für ' + config.length + ' Kind(er) zur Erstellung der Steuerbescheinigungen für Jahr ' + this.selectedYear);
            this.sending = false;
          })
      },

      createTaxCertificatePdf(referenceNumber, template) {
        this.sending = true;
        PdfService.createPdf(template)
        .then(response => {
          setTimeout(() => {
            this.progress += (100 * (template.length / this.selectedChildren.length));
            this.progressCount++;
            this.progressSuccessCount++;

            this.pdfBlobs.push(new Blob([response.data, 'utf-8'], {type: "application/pdf"}));
            this.pdfUrl = window.URL.createObjectURL(this.pdfBlobs[this.pdfBlobs.length - 1]);

            this.pdfLinks.push(document.createElement('a'));
            let id = this.organization.name + ' (' + referenceNumber + ') Steuerbescheinigung ' + this.selectedYear + ' ';
            if (template.length === 1) {
              id += template[0].child.masterdata.fullName
            } else {
              id += '(' + template.length + ' Kinder)';
            }
            this.pdfLinks[this.pdfLinks.length - 1].href = this.pdfUrl;
            this.pdfLinks[this.pdfLinks.length - 1].setAttribute('download', id + '.pdf');
            this.pdfLinks[this.pdfLinks.length - 1].setAttribute('id', id);
            document.body.appendChild(this.pdfLinks[this.pdfLinks.length - 1]);
            this.pdfLinks[this.pdfLinks.length - 1].click();

            this.sending = false;
            this.showDownloadTaxCertificateEndMessage();
          }, 250);
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der PDF Steuerbescheinigung für ' + template.length + ' Kinder und Jahr ' + this.selectedYear);
          this.progressCount++;
          this.progressFailureCount++;
          this.sending = false;
          this.showDownloadInvoiceEndMessage();
        })
      },

      showDownloadTaxCertificateEndMessage() {
        if (this.progressCount >= this.selectedChildren.length) {
          this.$store.commit('successMsg', this.progressSuccessCount + ' Dokument(e) erfolgreich herunterladen.');
          if (this.progressFailureCount > 0) {
            HttpErrorHandler.handleError(null, this, this.progressFailureCount + ' Dokument(e) konnten nicht herunterladen werden');
          }
        }
      },
    },

    computed: {

      facilitiesNotExists() {
        return this.organization && this.organization.facilities && this.organization.facilities.length <= 0;
      },

      pdfVisibile() {
        if (this.pdfLoading || !this.selectedChild || this.pdfUrl === null) {
          return 'display: none;';
        } else {
          return '';
        }
      },

      dataAvailable() {
        return this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
            this.user.allowedFacilities.length > 0 &&
            this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      user() {
        return this.$store.getters.user;
      },

      organization() {
        return this.$store.getters.organization;
      },

      getAvailableFacilities() {
        return this.$store.getters.availableFacilities;
      },

      labels() {
        return this.$store.getters.labels
      },

      isDevEnv() {
        return process.env.NODE_ENV === 'development';
      },

      childSelectionMode() {
        if (this.multiChildSelection) {
          return 'multiple';
        } else {
          return 'single';
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 800px;
  }

  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .pdf-frame {
    width: 100%;
    margin: 20px auto;
  }

  .md-content {
    max-width: 100%;
    max-height: 770px;
    overflow: auto;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-sub-title {
    color: lightseagreen;
    font-weight: 400;
    font-size: 1.25rem;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }
</style>
